<template>
  <div class="weeklyCalendar">
    <div class="date">
      {{ weeksDate[0].year }}年 <span>{{ weeksDate[0].month }}月</span>
      <!-- <img src="@/assets/images/more.png" /> -->
    </div>
    <div class="weekly">
      <div class="icon control" @click="prevWeek"><img src="@/assets/images/icon_left.png" /></div>
      <div class="weekly_content">
        <div class="day" :class="{'active': Year == item.year && Month == item.month && Day == item.date}" v-for="(item, index) in weeksDate" :key="index" :style="{background: bgcolor}" @click="changeDate(item.year, item.month, item.date)">
          <span class="dot" v-if="weeklyCalendarList.length > 0 && weeklyCalendarList[index].Value == 'true'"></span>
          <span class="weeksTitle">周{{ weeksTitle[index] }}</span>
          <span class="dayNum">{{ item.date }}</span>
        </div>
      </div>
      <div class="icon control" @click="nextWeek"><img src="@/assets/images/icon_right.png" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeeklyCalendar',
  data() {
    return {
      weeksTitle: ['日', '一', '二', '三', '四', '五', '六'],
      weeklyCalendarList: [],
      Year: '',
      Month: '',
      Day: '',
      currentDay: '',
      weekNum: 0,
      weeksDate: [],
      checkDate: '',
    }
  },
  props: {
    bgcolor: String,
    calendarCode: {
      type: String,
      default: ''
    },
    calendarTCode: {
      type: String,
      default: ''
    }
  },
  created() {
    this.initWeeklyCalendar();
  },
  methods: {
    initWeeklyCalendar() {
      let _date = new Date();
      this.Year = _date.getFullYear();
      this.Month = _date.getMonth() + 1;
      this.Day = _date.getDate();
      this.currentDay = _date.getDay();
      this.createDate(-this.currentDay);
      this.checkDate = this.Year + '-' + this.Month + '-' + this.Day;
    },
    countTime(n) {
      let _date = new Date();
      let getTime = _date.getTime() + (24 * 60 * 60 * 1000) * n;
      let needDate = new Date(getTime);
      let getYear = needDate.getFullYear();
      let getMonth = needDate.getMonth() + 1;
      let getDate = this.format(needDate.getDate());
      let obj = {
        'year': getYear,
        'month': getMonth,
        'date' : getDate
      };
      return obj
    },
    createDate(cDay) {
      this.weeksDate = [];
      for (let i = cDay; i < cDay + 7; i ++) {
        let data = this.countTime(i);
        this.weeksDate.push(data);
      }
      this.getScreen();
    },
    format(num) {
      let formatNum = num < 10 ? '0' + num : num;
      return formatNum;
    },
    // 切换周
    changeWeek(weekNum) {
      this.createDate(- this.currentDay + (7 * weekNum));
    },
    // 上一周
    prevWeek() {
      this.weekNum --;
		  this.changeWeek(this.weekNum);
    },
    // 下一周
    nextWeek() {
      this.weekNum ++;
		  this.changeWeek(this.weekNum);
    },
    // 切换日期
    changeDate(y, m, d) {
      this.checkDate = y + '-' + m +'-' + d;
      this.Year = y;
      this.Month = m;
      this.Day = d;
      this.$emit('activeDate', this.checkDate);
    },
    getScreen() {
      let startTime = this.weeksDate[0].year + '-' + this.weeksDate[0].month + '-' + this.weeksDate[0].date;
      let endTime = this.weeksDate[this.weeksDate.length-1].year + '-' + this.weeksDate[this.weeksDate.length-1].month + '-' + this.weeksDate[this.weeksDate.length-1].date;
      this.$axios.post('/Api/Api/Web/Article/GetScreen', {
        code: this.calendarCode,
        tcode: this.calendarTCode,
        state: startTime,
        end: endTime
      }).then((res) => {
        this.weeklyCalendarList =  res.Data.time;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.weeklyCalendar {
  .date {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    font-weight: bolder;
    span {
      color: #CA001B;
      margin: 0 2px 0 6px;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  .weekly {
    display: flex;
    height: 40px;
    margin: 12px 0 0;
    .control {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      background: #F9E5E8;
      border-radius: 4px;
      img {
        width: 8px;
      }
    }
    .weekly_content {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      .day {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 34px;
        background-color: #F7F7F7;
        border-radius: 4px;
        text-align: center;
        color: #333333;
        span {
          display: block;
        }
        .dot {
          position: absolute;
          top: -2px;
          right: -2px;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #CA001B;
        }
        .weeksTitle {
          font-size: 11px;
          margin-bottom: 3px;
        }
        .dayNum {
          font-size: 15px;
        }
      }
      .active {
        color: #FFFFFF !important;
        background-color: #CA001B !important;
      }
    }
  }
}
</style>