<template>
  <div class="countdown">
    倒计时
    <template v-if="day"><span class="block">{{day}}</span>天</template>
    <template v-if="hours"><span class="block">{{hours}}</span>小时</template>
    <template v-if="minutes"><span class="block">{{minutes}}</span>分</template>
    {{content}}
  </div>
</template>

<script>
export default {
  name: 'Countdown',
  data() {
    return {
      day: '',
      hours: '',
      minutes: '',
      content: ''
    }
  },
  props: {
    time: String
  },
  created() {
    this.countDowm(this.time)
  },
  methods: {
    countDowm(timestamp) {
      let timer = setInterval(() => {
        let nowTime = new Date();
        let endTime = new Date(timestamp);
        let t = endTime.getTime() - nowTime.getTime();
        if(t>0){
          let day = Math.floor(t/86400000);
          let hour=Math.floor((t/3600000)%24);
          let min=Math.floor((t/60000)%60);
          let sec=Math.floor((t/1000)%60);
          day = day < 10 ? "0" + day : day;
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          if(day > 0){
            this.day = day;
            this.hours = hour;
          }
          if(day <= 0 && hour > 0 ){
            this.hours = hour;
            this.minutes = min;
          }
          if(day <= 0 && hour <= 0){
            this.minutes = min;
          }
        } else {
          clearInterval(timer);
        }
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.countdown {
  .block {
    display: inline-block;
    padding: 1px 3px;
    border-radius: 2px;
    background-color: #CA001B;
    color: #FFFFFF;
    margin: 0 4px;
  }
}
</style>