<template>
  <div class="container mescroll-touch">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
    <div class="containerBox" id="toTop">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox" @click="$router.push('/search')">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
        <!-- <input class="searchInput" type="text" placeholder="请输入关键字搜索" disabled /> -->
      </div>
    </div>
    <div class="classify">
      <div class="classify_content" ref="wrapper">
        <div class="classify_wrapper">
          <span @click="toHome">全部</span>
          <span :class="{'active_classify': code == item.Code}" v-for="(item, index) in navBar" :key="index" :ref="item.Code" @click="toDepartment(item.Code, item.DisplayName)">{{item.DisplayName}}</span>
        </div>
      </div>
      <div class="more">
        <router-link to="/interest">
          <img class="addIcon" src="@/assets/images/add_comm_icon@2x.png" />
          <span>更多科室</span>
        </router-link>
      </div>
    </div>
    <div class="content">
      <div class="department_classify" v-if="code != 'mrpf'">
        <span :class="{'department_classify_active': SubjectTCode == ''}" @click="selectOperative('', '', '全部')">全部</span>
        <span :class="{'department_classify_active': SubjectTCode == item.TCcode}" v-for="(item, index) in operativeList" :key="index" @click="selectOperative(item.TCcode, item.code, item.Name)">{{item.Name}}</span>
      </div>
      <div class="banner" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="sonOperative" v-if="OperativeSecondLevelList.length > 0">
        <span :class="{'activeSonOperative': sonSubjectTCode == item.TCcode}" v-for="(item, index) in OperativeSecondLevelList" :key="index" @click="selectSonOperative(item.TCcode, item.Name)">{{item.Name}}</span>
      </div>
      <div class="section" v-if="code == 'chan' && SubjectTCode == 'chan|chan_sc|'">
        <div class="section_box" v-for="(item, index) in columnList" :key="index">
          <div class="section_item" v-if="item.DisplayName != '手术视频'" @click="selectColumns(item.TCode, item.Code, item.DisplayName)">
            <img :src="ColumnTCode == item.TCode ? require('@/assets/images/'+columns[item.Code.split('_')[1]].activeIcon) : require('@/assets/images/'+columns[item.Code.split('_')[1]].icon)" />
            <span :class="{'section_item_active': ColumnTCode == item.TCode}">{{item.DisplayName}}</span>
          </div>
        </div>
      </div>
      <div class="section" v-else>
        <div class="section_box" v-for="(item, index) in columnList" :key="index">
          <div class="section_item" @click="selectColumns(item.TCode, item.Code, item.DisplayName)">
            <img :src="ColumnTCode == item.TCode ? require('@/assets/images/'+columns[item.Code.split('_')[1]].activeIcon) : require('@/assets/images/'+columns[item.Code.split('_')[1]].icon)" />
            <span :class="{'section_item_active': ColumnTCode == item.TCode}">{{item.DisplayName}}</span>
          </div>
        </div>
      </div>
      <div class="classify_title">
        <div class="classify_title_left" v-if="activeColumn != 'hyzb' && activeColumn != 'hdpt'">
          <span :class="{'classify_title_active': ModuleType == 1}" @click="selectClassify(1)">最新上线</span>
          <span :class="{'classify_title_active': ModuleType == 2}" @click="selectClassify(2)">热门推荐</span>
        </div>
        <div class="classify_title_left" v-else>
          <span :class="{'classify_title_active': ModuleType == 6}" @click="selectClassify(6)">精彩回放</span>
          <span :class="{'classify_title_active': ModuleType == 5}" @click="selectClassify(5)">活动日历</span>
          <span :class="{'classify_title_active': ModuleType == 4}" @click="selectClassify(4)">正在直播</span>
        </div>
        <div class="classify_title_right" v-if="ColumnSecondLevelList.length > 0">
          <template v-if="ColumnSecondLevelList.length <= 1">
            <div class="classify_title_right_item" :class="{'classify_title_active': sonColumnTCode == item.TCcode}" v-for="(item, index) in ColumnSecondLevelList" :key="index" @click="selectSonColumn(item.TCcode, item.Name)">
              <span>{{item.Name}}</span>
              <img src="@/assets/images/splay_gk_icon@2x.png" />
            </div>
          </template>
          <template v-else-if="(activeColumn == 'hdpt' || activeColumn == 'hyzb') && ModuleType == 6">
            <div class="screenBtn" @click="showActivityFiltering">
              <span>筛选</span>
              <img src="@/assets/images/screen.png" />
            </div>
            <div class="screenTips" v-if="xwTipsShow">
              <span>点击按钮，查看您想要筛选的活动内容</span>
              <img @click="closeTips" src="@/assets/images/delete_home_icon@2x.png" />
            </div>
            <van-popup v-model="showActivityFilteringPicker" round position="bottom" :style="{ height: '40%' }">
              <div class="pickerContent">
                <div class="van-picker__toolbar toolbar">
                  <button type="button" class="van-picker__cancel" @click="onActivityFilteringCancel">取消</button>
                  <button type="button" class="van-picker__confirm" @click="onActivityFilteringConfirm">确认</button>
                </div>
                <div class="picker_content">
                  <div class="pickerBox">
                    <div class="picker_block" :class="{'activeBg': item.isChecked}" v-for="(item, index) in tempColumnSecondLevelList" :key="index" @click="selectActivityFiltering(index)">{{item.Name}}</div>
                  </div>
                </div>
              </div>
            </van-popup>
          </template>
        </div>
      </div>
      <div id="dataList">
        <div style="margin-bottom: 12px;">
          <weekly-calendar v-if="ModuleType == 5" bgcolor="#FFFFFF" :calendarCode="code" :calendarTCode="SubjectTCode" :key="calendarKey" @activeDate="activeDate"></weekly-calendar>
        </div>
        <waterfall :col="col" :width="ModuleType != 4 && ModuleType != 5 ? itemWidth : null" :gutterWidth="ModuleType != 4 && ModuleType != 5 ? gutterWidth : null" :data="list">
          <template v-if="ModuleType == 5">
            <div class="liveItem" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
              <div class="liveStatu" v-if="item.LiveStatus == 1 || item.LiveStatus == 2">
                <template v-if="item.LiveStatus == 1">
                  <span>正在直播</span>
                  <img class="icon_live" src="@/assets/images/live.png" />
                </template>
                <countdown v-if="item.LiveStatus == 2" :time="item.StartTime" :key="item.Id"></countdown>
              </div>
              <p class="date">{{ item.StartTime | timeQuantum(item.EndTime) }}</p>
              <p class="liveTitle">{{ item.SimpleTitle }}</p>
              <div class="WatchCount">
                <img src="@/assets/images/watch.png" />
                <span>{{ item.WatchCount }}人</span>
              </div>
            </div>
          </template>
          
          <template v-else>
            <div class="list" v-for="item in list" :key="item.Id" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
                <img class="playIcon" v-if="(item.Type == 1 || item.Type == 3) && ModuleType != 4" src="@/assets/images/play_comm_icon@2x.png" />
                <div class="liveStatu" v-if="ModuleType == 4">
                  <countdown v-if="item.LiveStatus == 2" :time="item.StartTime"></countdown>
                </div>
              </div>
              <div class="item_content">
                <span class="item_title" :class="{'item_titleHeight': ModuleType != 4 && ModuleType !=5}">{{item.Title}}</span>
                <template v-if="ModuleType != 4">
                  <p class="item_time">{{item.yyyymmdd}}</p>
                  <div class="item_nums">
                    <div class="nums">
                      <img src="@/assets/images/browse_comm_icon@2x.png" />
                      <span>{{item.WatchCount}}</span>
                    </div>
                    <div class="nums">
                      <img src="@/assets/images/like_comm_icon@2x.png" />
                      <span>{{item.LikeCount}}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="timeAndNum" v-if="item.LiveStatus == 2">
                    <p class="item_time">{{item.StartTime | dateFilter}}</p>
                    <div class="item_nums">
                      <div class="nums">
                        <img src="@/assets/images/browse_comm_icon@2x.png" />
                        <span>{{item.WatchCount}}</span>
                      </div>
                      <div class="nums">
                        <img src="@/assets/images/like_comm_icon@2x.png" />
                        <span>{{item.LikeCount}}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
      <!-- <div class="iconLink" @click="toQuestionnaire">
        <div class="iconBox">
          <img src="@/assets/images/wenjuan.png" />
        </div>
        <span>问卷调研</span>
      </div> -->
    </div>
    </div>
    </mescroll-vue>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import mixin from '../../common/mixin/mixins'
import storage from "../../common/utils/storage";
import BScroll from 'better-scroll'
import columns from '../../common/config/column'
import MescrollVue from 'mescroll.js/mescroll.vue'
import Sidebar from '../../components/sidebar.vue'
import WeeklyCalendar from '../../components/weeklyCalendar.vue'
import Countdown from '../../components/countdown.vue'

export default {
  name: 'Department',
  data() {
    return {
      scroll: null,
      operativeList: [],
      bannerList: [],
      SubjectTCode: '',
      OperativeCode: '',
      OperativeSecondLevelList: [],
      sonSubjectTCode: '',
      columnList: [],
      columns: columns.columns,
      ColumnTCode: '',
      ColumnCode: '',
      ColumnSecondLevelList: [],
      tempColumnSecondLevelList: [],
      sonColumnTCode: '',
      tempXWSonColumnTCode: '',
      ModuleType: 1,
      col: 2,
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			},
      sidebarShow: false,
      weeklyCalendarDate: '',
      calendarTCode: '',
      calendarKey: '',
      showActivityFilteringPicker: false
    }
  },
  mixins: [mixin],
  components: {
    MescrollVue,
    Sidebar,
    WeeklyCalendar,
    Countdown
  },
  computed: {
    ...mapState(['xwTipsShow']),
    code() {
      return this.$route.query.code;
    },
    STCode() {
      return this.$route.query.SubjectTCode;
    },
    CTCode() {
      return this.$route.query.ColumnTCode;
    },
    MType() {
      return this.$route.query.ModuleType;
    },
    activeColumn() {
      return this.ColumnCode.split('_')[1];
    },
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  watch: {
    navBar() {
      this.$nextTick(() => {
        this.scroll.refresh();
        let el = document.querySelector(".active_classify");
        this.scroll.scrollToElement(el,0,0)
      })
    },
    // code(newVal, oldVal) {
    //   if (oldVal) {
    //     storage.setStore('oldCode', oldVal);
    //   }
    //   if (newVal && newVal != storage.getStore('oldCode')) {
    //     this.getOperative();
    //     this.getBanner();
    //     this.getColumnList();
    //   }
    // },
    activeColumn() {
      if(this.activeColumn == 'hyzb' || this.activeColumn == 'hdpt') {
        if (this.MType) {
          this.ModuleType = this.MType;
        } else {
          this.ModuleType = 6;
        }
      } else {
        this.ModuleType = 1;
      }
    },
    SubjectTCode() {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    sonSubjectTCode() {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    ColumnTCode() {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    sonColumnTCode() {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    ModuleType(newVal) {
      if(newVal == 4 || newVal == 5) {
        this.col = 1;
      } else {
        this.col = 2;
      }
      
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    }
  },
  created() {
    // this.initBScroll();
    this.getOperative();
    this.getBanner();
    this.getColumnList();

    if(this.STCode) { 
      this.SubjectTCode = this.STCode;
    } else {
      this.SubjectTCode = '';
    }

    if(this.MType) {
      this.ModuleType = this.MType;
    }

    this.calendarTCode = this.code+'|';
  },
  mounted() {
    this.initBScroll();
    this.$store.commit('changeNavbarIndex', 0);
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
    this.$store.commit('changeNavbarIndex', 0);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  methods: {
    ...mapMutations(['changeXWTipsShow']),
    // 初始化BScroll
    initBScroll() {
      this.$nextTick(() => {
        this.scroll = new BScroll(this.$refs['wrapper'], {
              startX: 0,
              click: true,
              scrollX: true,
              scrollY: false,
              eventPassthrough: 'vertical'
        })
        // console.log(this.$refs[this.code].getBoundingClientRect)
        // let el = document.querySelector(".active_classify");
        // this.scroll.scrollToElement(el,0,0)
      })
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      // if(this.SubjectTCode !='' && this.ColumnTCode != '' && this.ModuleType != '') {
      if(this.ColumnTCode != '' && this.ModuleType != '' && this.ModuleType != 4 && this.ModuleType != 5) {
        this.getList(page.num, page.size);
      } else {
        this.getLiveList();
      }
		},
    toHome() {
      this.BuriedPoint(1, '首页', '', '/home');
      this.$router.push('/home')
    },
    // 获取亚术式
    async getOperative() {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=2&pcode=' + this.code)
      this.operativeList = res.Data;
      if (res.Data.length > 0) {
        // this.SubjectTCode = res.Data[0].TCcode;
        this.OperativeCode = res.Data[0].code;
        this.getOperativeSecondLevel(this.OperativeCode);
      }
    },
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code='+ this.code).then(res => {
        this.bannerList = res.Data;
      })
    },
    // 获取栏目
    async getColumnList() {
      let res = await this.$axios.get('/Api/api/Web/Subject/GetColumnListByCode?Code='+ this.code);
      this.columnList = res.Data;
      if (res.Data.length > 0) {
        if (this.CTCode) {
          this.ColumnTCode = this.CTCode;
          let data = this.columnList.find(item => item.TCode == this.CTCode);
          this.ColumnCode = data.Code;
        } else {
          this.ColumnTCode = this.columnList[0].TCode;
          this.ColumnCode = this.columnList[0].Code;
        }
        this.getColumnSecondLevel();
      }
    },
    // 获取亚术式二级分类
    async getOperativeSecondLevel(code) {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=2&pcode=' + code);
      this.OperativeSecondLevelList = res.Data;
      if(this.OperativeSecondLevelList.length > 0) {
        this.sonSubjectTCode = this.OperativeSecondLevelList[0].TCcode;
      } else {
        this.sonSubjectTCode = '';
      }
    },
    // 获取专家用线解读
    async getColumnSecondLevel() {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=3&pcode=' + this.ColumnCode);
      this.ColumnSecondLevelList = res.Data;
      this.tempColumnSecondLevelList = res.Data;
    },
    // 选择亚术式
    selectOperative(TCcode, code, name) {
      this.SubjectTCode = TCcode;
      this.calendarTCode = TCcode;
      this.getOperativeSecondLevel(code);
      this.BuriedPoint(1, name, TCcode, `/department?code=${this.code}`);
      this.calendarKey = new Date().getTime();
      this.weeklyCalendarDate = '';
    },
    // 选择亚术式子分类
    selectSonOperative(TCcode, name) {
      this.sonSubjectTCode = TCcode;
      this.weeklyCalendarDate = '';
      this.BuriedPoint(1, name, TCcode, `/department?code=${this.code}`);
    },
    // 选择栏目
    selectColumns(TCode, code, name) {
      this.ColumnTCode = TCode;
      this.ColumnCode = code;
      this.sonColumnTCode = '';
      this.weeklyCalendarDate = '';
      this.getColumnSecondLevel();
      this.BuriedPoint(1, name, TCode, `/department?code=${this.code}`);
    },
    // 选择栏目子分类
    selectSonColumn(TCcode, name) {
      if (this.sonColumnTCode == TCcode) {
        this.sonColumnTCode = '';
      } else {
        this.sonColumnTCode = TCcode;
      }
      this.BuriedPoint(1, name, TCcode, `/department?code=${this.code}`);
    },
    // 心外活动筛选
    selectActivityFiltering(index) {
      this.tempColumnSecondLevelList[index].isChecked = !this.tempColumnSecondLevelList[index].isChecked;
      this.$forceUpdate();
    },
    // 加载对应分类下的list
    selectClassify(type) {
      this.ModuleType = type;
    },
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;

      let SubjectTCode = '';
      if (this.sonSubjectTCode) {
        SubjectTCode = this.sonSubjectTCode;
      } else {
        SubjectTCode = this.SubjectTCode;
      }

      let ColumnTCode = '';
      if (this.sonColumnTCode) {
        ColumnTCode = this.sonColumnTCode;
      } else {
        ColumnTCode = this.ColumnTCode;
      }

      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: SubjectTCode,
          ColumnTCode: ColumnTCode,
          ModuleType: this.ModuleType,
          Code: this.ColumnCode,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then(res => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          this.$forceUpdate()
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        })
      }, 300);
    },
    // 获取直播列表数据
    getLiveList() {
      this.$axios.post('/Api/Api/Web/Article/GetLiveArticleList', {
        time: this.weeklyCalendarDate,
        code: this.code,
        tcode: this.SubjectTCode
      }).then(res => {
        let list = res.Data;
        let liveList = [];
        let previewList = [];
        list.forEach(item => {
          if(item.LiveStatus == 1) {
            liveList.push(item);
          } else if(item.LiveStatus == 2) {
            previewList.push(item);
          }
        });

        if(this.ModuleType == 5) {
          this.list = list;
        } else if(this.ModuleType == 4) {
          if(liveList.length > 0) {
            this.list = liveList;
          } else {
            this.list = previewList;
          }
        }

        this.$nextTick(() => {
          this.mescroll.endSuccess(this.list.length)
        })
      })
    },
    activeDate(date) {
      this.weeklyCalendarDate = date;
      this.mescroll.resetUpScroll(); // 刷新列表数据
    },
    popupClose(val) {
      this.sidebarShow = val;
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    imgclick(UrlLink) {
			let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    closeTips() {
      this.changeXWTipsShow(false);
    },
    showActivityFiltering() {
      this.showActivityFilteringPicker = true;
      this.tempColumnSecondLevelList = JSON.parse(JSON.stringify(this.ColumnSecondLevelList));
    },
    onActivityFilteringCancel() {
      this.showActivityFilteringPicker = false;
      this.tempColumnSecondLevelList = JSON.parse(JSON.stringify(this.ColumnSecondLevelList));
      this.$forceUpdate();
    },
    onActivityFilteringConfirm() {
      this.showActivityFilteringPicker = false;
      this.ColumnSecondLevelList = JSON.parse(JSON.stringify(this.tempColumnSecondLevelList));
      this.sonColumnTCode = "";
      this.ColumnSecondLevelList.forEach(item => {
        if(item.isChecked) {
          this.sonColumnTCode += item.TCcode + ',';
        }
      });
      this.sonColumnTCode = this.sonColumnTCode.substr(0, this.sonColumnTCode.length - 1);
    },
    toQuestionnaire() {
      // window.location.href = 'https://www.wjx.cn/vm/etNd2Om.aspx#';
      this.$router.push('/SSIquestionnaire');
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 16px 0 56px;
  box-sizing: border-box;
  background-color: #F9F9F9;
  .containerBox {
    background-image: url('../../assets/images/bg_comm_png@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 16px;
  }
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 60px;
    height: auto;
  }
  .top {
    margin-bottom: 10px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .classify {
    display: flex;
    color: #FFFFFF;
    height: 31px;
    padding: 0 12px;
    .classify_content {
      display: flex;
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      .classify_wrapper {
        white-space: nowrap;
        span {
          position: relative;
          margin-right: 20px;
        }
        .active_classify {
          font-size: 18px;
          font-weight: bold;
        }
        .active_classify::after {
          content: "";
          width: 22px;
          height: 3px;
          border-radius: 3px;
          background-color: #FFFFFF;
          position: absolute;
          top: 25px;
          left: 50%;
          transform: translateX(-11px);
        }
      }
    }
    .more {
      font-size: 12px;
      margin-left: 10px;
      a {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        margin-top: 6px;
      }
      .addIcon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        opacity: 0.8;
      }
    }
  }
  .content {
    min-height: 100%;
    margin-top: 1px;
    padding: 10px 12px 0;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
  }
  .content:before{
    content: '';
    display: table;
  }
  .department_classify {
    display: flex;
    flex-wrap: wrap;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23%;
      height: 26px;
      border-radius: 26px;
      border: 1px solid #CCCCCC;
      font-size: 11px;
      color: #666666;
      box-sizing: border-box;
      margin-top: 10px;
    }
    span:not(:nth-child(4n)) {
      margin-right: calc(8% / 3);
    }
    .department_classify_active {
      color: #CA001B;
      border: 1px solid #CA001B;
    }
  }
  .banner {
    // height: 185px;
    margin: 20px 0 15px 0;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
    overflow: hidden;
    .bannerImg {
      width: 100%;
      display: block;
    }
  }
  .sonOperative {
    span {
      font-size: 12px;
      color: #666666;
      margin-right: 20px;
    }
    .activeSonOperative {
      font-size: 15px;
      color: #CA001B;
      font-weight: bold;
    }
  }
  .section {
    display: flex;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px 0;
    margin-bottom: 14px;
    margin-top: 20px;
    .section_box {
      flex: 1;
      text-align: center;
    }
    .section_item {
      // flex: 1;
      // text-align: center;
      img {
        display: block;
        margin: 0 auto 5px;
        width: 50px;
        height: 50px;
      }
      span {
        font-size: 12px;
        color: #333333;
      }
      .section_item_active {
        color: #CA001B !important;
        font-weight: bold;
      }
    }
  }
  .classify_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 15px;
    color: #333333;
    .classify_title_left {
      span {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        line-height: 28px;
      }
    }
    .classify_title_right {
      position: relative;
      display: flex;
      .classify_title_right_item {
        display: flex;
        align-items: center;
        img {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }
      }
      .screenBtn {
        margin-top: 4px;
        font-size: 14px;
        color: #CA001B;
        img {
          width: 12px;
          height: 12px;
          vertical-align: middle;
          margin-left: 3px;
        }
      }
      .screenTips {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        right: -2px;
        bottom: 35px;
        z-index: 99;
        width: 256px;
        height: 30px;
        line-height: 30px;
        border-radius: 10px;
        padding: 0 15px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.75);
        font-size: 12px;
        color: #FFFFFF;
        img {
          width: 10px;
          height: 10px;
        }
      }
      .screenTips:before{
        content: "";
        width: 0px;
        height: 0px;
        border-top: 8px solid rgba(0, 0, 0, 0.75);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        top: 30px;
        right: 9px;
      }
    }
  }
  .classify_title_active {
    color: #CA001B;
    font-weight: bold;
  }
  .classify_title_active::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #CA001B;
    border-radius: 2px;
    left: 50%;
    bottom: 0;
    transform: translateX(-9px);
  }
  .list {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .item_img {
      position: relative;
      .item_cover {
        display: block;
        width: 100%;
      }
      .playIcon {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 30px;
        height: 30px;
      }
      .liveStatu {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 11px;
        color: #CA001B;
        padding: 2px 8px;
        background-color: #F9E5E8;
        border-radius: 0 0px 0 10px;
      }
    }
    .item_content {
      padding: 5px 8px 8px;
      .item_title {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        word-wrap: break-word;
      }
      .item_titleHeight {
        height: 38px;
      }
      .timeAndNum {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .item_time {
        font-size: 12px;
        color: #999999;
        line-height: 24px;
      }
      .item_label {
        display: flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          border-radius: 2px;
          padding: 0 6px;
          margin-right: 6px;
          margin-bottom: 5px;
          background-color: rgba(255, 73, 98, 0.15);
          font-size: 11px;
          color: #CA001B;
        }
      }
      .item_nums {
        display: flex;
        justify-content: flex-end;
        .nums {
          display: flex;
          align-items: center;
          img {
            width: 12px;
            margin: 0 2px 0 6px;
          }
          span {
            font-size: 11px;
            color: #666666;
          }
        }
      }
    }
  }
  .liveItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 84px;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 4px 12px;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: #FFFFFF;
    .liveStatu {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 11px;
      color: #CA001B;
      padding: 2px 8px;
      background-color: #F9E5E8;
      border-radius: 0 10px 0 10px;
      .icon_live {
        width: 12px;
        height: 12px;
        margin-left: 6px;
        opacity: 1 !important;
      }
    }
    .date {
      font-size: 12px;
      color: #999999;
    }
    .liveTitle {
      font-size: 15px;
      color: #333333;
      font-weight: bold;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
    .WatchCount {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999999;
      img {
        width: 12px;
        margin-right: 2px;
      }
    }
  }
}

.picker_content {
    padding: 12px 6px;
    .pickerBox {
      display: flex;
      flex-wrap: wrap;
    }
    .picker_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(calc(100% / 3) - 12px);
      height: 36px;
      text-align: center;
      border: 1px solid rgba(153, 153, 153, 0.5);
      box-sizing: border-box;
      margin: 6px;
      border-radius: 4px;
      color: #333333;
      font-size: 14px;
    }
    .activeBg {
      border: none;
      color: #C70C3C;
      background-image: url('../../assets/images/select_system_btn@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

.screenItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 132px;
  height: 32px;
  // line-height: 32px;
  font-size: 13px;
  color: #333333;
  padding: 0 12px;
  img {
    width: 12px;
    height: 12px;
  }
}
.liveScreenActive {
  color: #CA001B;
}
.iconLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 120px;
  right: 8px;
  .iconBox {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 3px;
  }
  img {
    width: 40px;
    height: 40px;
  }
  span {
    font-size: 10px;
    color: #CA001B;
  }
}

/deep/ .van-swipe__indicators {
  top: 90%;
}
</style>